import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Utils from "../../utils/Utils";
import DOMPurify from "dompurify";
import {domToReact} from "html-react-parser";  // Import sanitizer


function CategoriesComp () {
    const [categories, setCategories] = useState([]);
    const [content, setContent] = useState(null);

    useEffect(() => {
        const dataCats = []
        window.scrollTo(0, 0)
        const getContent = async () => {
            let resp = await Utils.get_page(3)
            setContent(DOMPurify.sanitize(resp.data.content))
            console.log(resp.data)
            document.title = new DOMParser().parseFromString(resp.data.title, "text/html").body.textContent;
        }

       /* const getCategories = async () => {
            const resp = await Utils.getAllDirs()
            const data = resp.data
            console.log(resp.data)
            // eslint-disable-next-line array-callback-return
            data.map(d => {
                dataCats.push(d)
            })
            setCategories(dataCats)
            console.log(dataCats)
        }
        getCategories()*/
        getContent();
    }, [])
        const transform = (node) => {
        if (!node || !node.type) return node; // Prevent null errors

            // Convert <a> tags into <Link> for internal links
            if (node.type === "tag" && node.name === "a") {
                const href = node.attribs?.href || "";

                if (href.startsWith("/")) {
                    return <Link to={href}>{domToReact(node.children)}</Link>; // React Router link
                }
                return (
                    <a href={href} target="_blank" rel="noreferrer">
                        {domToReact(node.children)}
                    </a>
                ); // External link
            }
        return node; // Return unchanged nodes
    };
    return (
        <div>
             <div className={"flex-child-element:first-child"} style={{float:"right", width:"27%", marginTop:"75px", marginRight:"35px"}}>
                <h4>Please choose from the categories below:</h4>
                 <div style={{marginTop:"40px"}}>
                   <div>

                    </div>
                 </div>
             </div>
             <div align={"left"} className={"flex-child-element"} style={{width:"55%", marginTop:"60px", marginLeft:"25px"}}>
                 <h2>Browse Datasets by Category</h2>
             <br/>
                 <div dangerouslySetInnerHTML={{ __html: content }} />
             </div>
        </div>
    )
}

export default CategoriesComp