import {Link, useNavigate, useOutlet} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import parse from "html-react-parser";  // Import sanitizer
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/button.css";
import LogoutModal from "../../services/modals/LogoutModal";
import Utils from "../../utils/Utils";


function DashboardComp ( ) {
    const outlet = useOutlet();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const handleShow = () => setIsModalOpen(true)
    const handleClose = () => setIsModalOpen(false)
    const [pages, setPages] = useState([])
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Admin Dashboard";
        if (token)
        {
            const pageList = []
            const getPages = async () => {
                let resp = await Utils.get_all_pages(token)
                let list = resp.data
                // eslint-disable-next-line array-callback-return
                Object.entries(list).map(([key, value]) => {
                    pageList.push(value)
                })
                setPages(pageList)
            }
            getPages()
        }
        else
        {
            navigate("/")
        }

    }, [navigate, token])
    const handleChange = (event) => {
        if (event.target.value === "10") {
            console.log("You chose datasets!", event.target.value)
            navigate("/admin_only/page/datasets")
        }
        else {
            const selectedPath = event.target.value;
            setSelected(selectedPath)
            if (selectedPath) {
                navigate(`/admin_only/page/${selectedPath}`); // Redirect to the selected path

            }
        }
    };
    const logMeOut = () => {
        setIsModalOpen(true);
        Utils.logout()
        localStorage.removeItem("user");
        localStorage.removeItem("token")
        localStorage.removeItem("refresh_token");
        setIsModalOpen(false);
        navigate('/');
    }

    return (
        <div style={{marginTop: "75px", marginLeft: '25px', marginBottom:'30px'}}>
            <h2>Welcome {user} to ADMIN Dashboard</h2>
            <br/>
            <button className={'button'} onClick={handleShow}>
                Logout
            </button>
            <LogoutModal show={isModalOpen} handleClose={handleClose} handleLogout={logMeOut} />
            <br/>
            <div style={{width: "600px", height: "200px", border: 1, borderColor: "black", borderRadius: "10px"}}>
                <h3>Please choose admin action:</h3>
                <div style={{display: "inline"}}>
                    <ul  align={"left"} style={{listStyle: "none"}}>
                        <li><Link to={"/admin_only/submits"}>Show Submissions</Link></li>
                        <li><Link to={"/admin_only/new_dataset"}>Add New Dataset</Link></li>
                        <li>
                            <Link to={"/admin_only/edit"} aria-disabled={true}>Edit Page Content</Link>
                            &nbsp;&nbsp;&nbsp;
                            <select id={"selection"} value={selected} onChange={handleChange}>
                                <option  id={"edit"} value="">Select a Page</option>
                                {pages.map((option) => (
                                <option id={option.id} name={option.title} key={option.id} value={option.id}>
                                    {parse(option.title)}
                                </option>
                                ))}
                            </select>
                        </li>
                    </ul>
                </div>
            </div>
            {outlet}
        </div>

    )
}

export default DashboardComp



