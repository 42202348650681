import {useEffect, useState,} from "react";
import '../css/gifIverlay.css'
import '../App.css'
import '../css/button.css'
//import ItemListComp from "../../services/CollapseExpand/ItemListComp";
import Utils from "../utils/Utils";
import processStringToHtml from '../utils/NewTranslator';
import PaginationTable from "../services/Pagination";

function LinksComp() {
    const pathname = window.location.pathname.slice(1)
    const [linksArray, setLinksArray] = useState([])
    const mainDir = pathname.split("/", 1)
    const [mainTitle, setMainTitle] = useState('')
    const [dirContent, setDirContent] = useState([]);
    const [dirDescription, setDirDescription] = useState('')
    const processedHtml = processStringToHtml(dirDescription, linksArray);
    useEffect(() => {
        window.scrollTo(0, 0)
        const getSub = async (dirPath) => {
            const dirData = await Utils.getSubDirs(dirPath)
            let data = dirData.data;
            setDirContent(data)

        }
        if (pathname.includes('/')) {
            let mainName = pathname.split('/')[0]
            setMainTitle(localStorage.getItem(mainName))
            getDescription(mainName)
        }
        else {
            setMainTitle(localStorage.getItem(pathname))
            getDescription(pathname)
        }
        getSub(pathname).then((response) => {
            document.title = mainTitle;
        })
      .catch((error) => {
        console.error('Error:', error);
      });
        const links = localStorage.getItem('links')
        if (links) {
            setLinksArray(JSON.parse(links))
        }
    }, [mainTitle, pathname]);
    const getDescription = async (mainName) => {
                let resp = await Utils.getAllDirs()
                let dirData = resp.data;
                dirData.forEach((item) => {
                    if (item.name === mainName) {
                        setDirDescription(item.description)
                    }
                })
            }
    return (
        <div>
            <div>
                <div className={"flex-child-element:first-child"} align={"left"}
                     style={{float: "right", width: "27%", marginTop: "60px", marginRight: "35px"}}>
                    <h3 style={{marginBottom: "40px"}}>{mainDir}</h3>
                </div>
                <div className={"flex-child-element"}
                     style={{marginLeft: "25px", marginTop: "75px", marginBottom: "30px", width: "55%"}}>
                    <div align={"left"}>
                        <h2>{mainTitle}</h2>
                        <div style={{marginTop:'20px'}}/>{processedHtml}
                        <br/>
                    </div>
                    <div align={"center"}>
                        <PaginationTable data={dirContent} mainPath={pathname}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinksComp


/*

<div style={{ marginBottom: "25px"}}>
                    {
                        // eslint-disable-next-line array-callback-return
                        dirContent.map((y, index) => {
                            if (y.name === sub) {
                                return (<div key={index}>{y.above}</div>)
                            }
                        })
                    }
                </div>
            <br/>
            <div style={{padding: "20px"}}>
                <Pagination />
            </div>
            <div>
                <div>
                    {
                        // eslint-disable-next-line array-callback-return
                        children.map((z, index) => {
                            if (z.name === sub) {
                                let items = z.accordion
                                if (items) {
                                    return (<ItemListComp items={items}/>)
                                }
                            }
                        })
                    }
                </div>
        </div>
*/