import React, { useState, useEffect } from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Utils from "../../utils/Utils";
import {useParams, useNavigate} from "react-router-dom";
import UpdateModal from "../../services/modals/UpdateModal";

function EditablePage() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleShow = () => setIsModalOpen(true)
    const handleClose = () => setIsModalOpen(false)
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [right_content, setRight_content] = useState("");
  const token = localStorage.getItem("token");
  const pageId = useParams().pageId;
  const dataset_name = pathname.substring(pathname.lastIndexOf("/") + 1)

  useEffect(() => {
    console.log("page: ", pathname);
    if (!token) {
            navigate('/')
        }
    else {
        if (pathname.includes("datasets")) {
            const getDatasetContent = async () => {
                await Utils.get_dataset(dataset_name, token).then((res) => res).then((data) => {
                    setTitle(data.data.title);
                    setContent(data.data.description);
                    setRight_content(data.data.links);
                })
            }
            getDatasetContent()
        } else {
            const getContent = async () => {
                await Utils.get_page(pageId).then((res) => res).then((data) => {
                    setTitle(data.data.title)
                    setContent(data.data.content);
                    setRight_content(data.data.right_content);
                })
            }
            getContent();
        }
    }
  }, [dataset_name, navigate, pageId, pathname, title, token]);

  const handleSave = async ( ) => {
      // Save edited content back to Flask backend
      if (!pathname.includes("datasets")) {
           setIsModalOpen(true);
          await Utils.save_page(pageId, title, content, right_content, token);
          setIsModalOpen(false);
          navigate('/admin_only');
      }
      else
      {
           setIsModalOpen(true);
          await Utils.save_dataset(pageId, title, content, right_content, token);
          setIsModalOpen(false);
          navigate('/admin_only');
      }
  };

  return (
    <div>
      <div align={"left"}
                 style={{marginLeft: "50px", marginTop: "30px", marginBottom: "30px", width: "60%"}}>
          <div>
              <ReactQuill theme="snow" value={title} onChange={setTitle} />
          </div>
          <br/>
        <div>
            <ReactQuill theme="snow" value={content} onChange={setContent} />
        </div>
          <div align={"right"}
                 style={{marginLeft: "50px", marginTop: "30px", marginBottom: "30px", width: "60%"}}>
              <ReactQuill theme="snow" value={right_content} onChange={setRight_content} />

                </div>
      </div>
        <button className={"custom-button"} onClick={handleShow} style={{ marginTop: "10px" }}>Save</button>
        <UpdateModal show={isModalOpen} handleClose={handleClose} handleUpdate={handleSave} />
    </div>
  );
}

export default EditablePage;
