import React, {useEffect, useState} from "react";
import '../../App.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Utils from "../../utils/Utils";
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";
import parse, {domToReact} from "html-react-parser";

function RepComp () {
    const [content, setContent] = useState("");
    const [rightContent, setRightContent] = useState("");
    const [accordionItems, setAccordionItems] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0)
        const getContent = async () => {
            let resp = await Utils.get_page(6)
            setContent(DOMPurify.sanitize(resp.data.content))
            setRightContent(DOMPurify.sanitize(resp.data.right_content))
            console.log(resp.data.accordion.title)
            console.log(resp.data.right_content)
            setAccordionItems((resp.data.accordion))
            document.title = new DOMParser().parseFromString(resp.data.title, "text/html").body.textContent;
        }
        getContent()
    }, []); // Runs when content updates

    const transform = (node) => {
        if (!node || !node.type) return node; // Prevent null errors

            // Convert <a> tags into <Link> for internal links
            if (node.type === "tag" && node.name === "a") {
                const href = node.attribs?.href || "";

                if (href.startsWith("/")) {
                    return <Link to={href}>{domToReact(node.children)}</Link>; // React Router link
                }
                return (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        {domToReact(node.children)}
                    </a>
                ); // External link
            }
        return node; // Return unchanged nodes
    };
    return (
        <div className={"App"}>
            <div align={"left"} className={"flex-child-element:first-child"}
                style={{float: "right", width: "27%", marginTop: "85px", marginRight: "35px"}}>
                    {parse(rightContent, {replace: transform})}
            </div>
            <div className={"flex-child-element"} align={"left"}
                     style={{marginLeft: "50px", marginTop: "10px", marginBottom: "30px", width: "60%"}}>
                     <h2 align={"center"}>IACDC:</h2>
                <br/>
                     <div>
                         {parse(content, {replace: transform})}
                     </div>
                     <div className="accordion mt-4" id="dynamicAccordion">
                 {accordionItems.map((item, index) => (
                     <div className="accordion-item" key={index} >
                        <h2 className="accordion-header" id={`heading${index}`} >
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                style={{backgroundColor:"#D3D3D3"}}
                            >
                                {<div dangerouslySetInnerHTML={{ __html: item.header}} />}
                            </button>
                        </h2>
                        <div
                            id={`collapse${index}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#dynamicAccordion"
                        >
                            <div className="accordion-body" align={"left"}>
                                <div dangerouslySetInnerHTML={{__html: item.content}} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
         </div>
        </div>
    )
}

export default RepComp