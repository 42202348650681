import axios from "axios";
const baseURL = "https://www.iacdc.tau.ac.il"


const getAllDirs = async () => await axios.get(`${baseURL}/api/main`);

const getSubDirs = async (name) => await axios.get(`${baseURL}/api/sub/${name}/`)

const submission = async (form) => await axios.post(`${baseURL}/submit`, form, {headers: {'Content-Type': 'application/json'}})

const dataRequest = async (form) => await axios.post(`${baseURL}/request_data`, form, {headers: {'Content-Type': 'application/json'}})

const login = async (form) => await axios.post(`${baseURL}/login/auth`, form, {headers: {'Content-Type': 'application/json'}})

const logout = async () => await axios.post(`${baseURL}/logout`)

const refreshToken = async (token) => await axios.post("/admin_only/refresh", {}, { headers: { Authorization: `Bearer ${token}` } });

const showSubmits = async (token) => await axios.get(`${baseURL}/admin_only/submits`, {headers: {"Authorization": `Bearer ${token}`}})

const reply = async (form, token) => await axios.post(`${baseURL}/admin_only/reply`, form, {headers: {"Authorization": `Bearer ${token}`}})

const download = async (file) => await axios.get(`${baseURL}/links/${file}`, {responseType: 'blob'})

const multiDownloads = async (selected_data) => await axios.post(`${baseURL}/download-selected`, selected_data, {responseType: 'blob'})

const get_page = async (id) => await axios.get(`${baseURL}/api/pages/${id}`)

const get_all_pages = async (token) => await axios.get(`${baseURL}/admin_only/menu`, {headers: {"Authorization": `Bearer ${token}`}})

const save_page = async (id, title, content, right_content, token) => await axios.post(`${baseURL}/admin_only/pages/${id}`, {title, content, right_content}, {headers: {"Authorization": `Bearer ${token}`}})

const get_dataset = async (id, token) => await axios.get(`${baseURL}/admin_only/pages/datasets/${id}`, {headers: {"Authorization": `Bearer ${token}`}})

const save_dataset = async (id, title, content, right_content, token) => await axios.post(`${baseURL}/admin_only/pages/datasets/save/${id}`, {title, content, right_content}, {headers: {"Authorization": `Bearer ${token}`}})

const newDataset = async (path, token) => await axios.get(`${baseURL}/admin_only/new?path=${encodeURIComponent(path)}`,  {headers: {"Authorization": `Bearer ${token}`}})

const symlink = async (dataset, token) => await axios.post(`${baseURL}/admin_only/symlink`, dataset, {headers: {"Authorization": `Bearer ${token}`}})

// eslint-disable-next-line import/no-anonymous-default-export
export default {getAllDirs,
                getSubDirs,
                submission,
                dataRequest,
                get_page,
                login,
                logout,
                showSubmits,
                download,
                multiDownloads,
                save_dataset,
                newDataset,
                symlink,
                reply,
                save_page,
                get_all_pages,
                refreshToken,
                get_dataset
                };