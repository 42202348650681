import React, {useEffect, useState} from "react";
import Utils from "../../utils/Utils";
import "../../css/button.css"
import {useNavigate} from "react-router-dom";

const Datasets = () => {
    const navigate = useNavigate();
    const [suberName, setSuberName] = useState([])
    const token = localStorage.getItem("token");
    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        else {
            const subsname = []

            const getDirs = async () => {
                let resp = await Utils.getAllDirs()
                const dirData = resp.data;
                // eslint-disable-next-line array-callback-return
                Object.entries(dirData).map(([key, value]) => {
                    subsname.push(value)
                })
                setSuberName(subsname)
            }
           getDirs()
        }
    }, [navigate, token]);

    const goTo = (name) => {
        navigate(`/admin_only/page/datasets/${name}`);
    }
        return (
            <div style={{marginBottom:'30px'}}>
                <h3>Please choose dataset to edit:</h3>
                <div style={{display: "inline"}}>
                    <ul  align={"left"} style={{listStyle: "none"}}>
                        {suberName.map((x, index) => {
                            return (
                            <li key={x.id} onClick={() => goTo(x.name)}>{x.title}</li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
        );
    }

export default Datasets