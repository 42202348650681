import {useEffect, useState} from "react";
import Utils from "../../utils/Utils";
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";
import {domToReact} from "html-react-parser";  // Import sanitizer

function BatchComp () {
    const [content, setContent] = useState(null);
    useEffect(() => {
      window.scrollTo(0, 0)
        const getContent = async () => {
            let resp = await Utils.get_page(5)
            setContent(DOMPurify.sanitize(resp.data.content))
            document.title = new DOMParser().parseFromString(resp.data.title, "text/html").body.textContent;
        }
        getContent();
    }, [])
        const transform = (node) => {
        if (!node || !node.type) return node; // Prevent null errors

            // Convert <a> tags into <Link> for internal links
            if (node.type === "tag" && node.name === "a") {
                const href = node.attribs?.href || "";

                if (href.startsWith("/")) {
                    return <Link to={href}>{domToReact(node.children)}</Link>; // React Router link
                }
                return (
                    <a href={href} target="_blank" rel="noreferrer">
                        {domToReact(node.children)}
                    </a>
                ); // External link
            }
        return node; // Return unchanged nodes
    };
    return (
        <div >
            <h2 align={"center"}>Batch Downloads from the IACDC</h2>
            <div align={"left"} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    )
}

export default BatchComp