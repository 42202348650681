import React, {Fragment} from 'react';
import '../App.css'
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import Header from "../services/headers and footers/Header";
import LinksComp from "./LinksComp";
import AboutComp from "./about/About";
import BatchComp from "./data access/Batch";
import RepComp from "./data access/Repository";
import OutIACDC from "./data access/OnlineRessources";
import Contact from "./contact/Contact";
import LoginComp from "./login/Login";
import DashboardComp from "./admin/Dashboard";
import Footer from "../services/headers and footers/Footer";
import DataRequestComp from "./data-request/DataRequest";
import RequestFormComp from "./data-request/RequestForm";
import Submissions from "./admin/Submits";
import NewDataset from "./admin/NewDataset";
import CategoriesComp from "./categories/Categories";
import DataAccessComp from "./data access/DataAccess";
import HomePageComp from "./HomePage";
import EditablePage from "./admin/PageEdit";
import Datasets from "./admin/Datasets";

function MainPage() {

    return (
        <div className={'App'}>
            <div><Header/></div>
            <div>
                <Fragment>
                    <Router basename={'/'}>
                        <Routes>
                            <Route path={"/"} exact element={<HomePageComp/>}/>
                            <Route path={"/:subDir"} element={<LinksComp/>}/>
                            <Route path={"/:subdir/:subsubdir/*"} element={<LinksComp/>}/>
                            <Route path={"/about"} element={<AboutComp/>}/>
                            <Route path={"batch-downloads"} element={<BatchComp/>}/>
                            <Route path={"/categories"} element={<CategoriesComp/>}/>
                            <Route path={"/data_access"} element={<DataAccessComp/>}/>
                            <Route path={"research-repository"} element={<RepComp/>}/>
                            <Route path={"Online-ressources"} element={<OutIACDC/>}/>
                            <Route path={"/data-requests"} element={<DataRequestComp/>}/>
                            <Route path={"/data-requests/request"} element={<RequestFormComp/>}/>
                            <Route path={"/contact"} element={<Contact/>}/>
                            <Route path={"login"} element={<LoginComp/>}/>
                            <Route exact path='/admin_only' element={<DashboardComp/>}>
                                <Route path={"submits"} element={<Submissions/>}/>
                                <Route path={"new_dataset"} element={<NewDataset/>}/>
                                <Route path={"page/:pageId"} element={<EditablePage/>}/>
                                <Route path={"page/datasets"} element={<Datasets/>}/>
                                <Route path={"page/datasets/:pageId"} element={<EditablePage/>}/>
                            </Route>
                        </Routes>
                    </Router>
                </Fragment>
            </div>
            <div><Footer/></div>
        </div>
    );
}

export default MainPage;
