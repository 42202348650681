import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Utils from "../../utils/Utils";
import DOMPurify from "dompurify";
import parse, {domToReact} from "html-react-parser";  // Import sanitizer


function AboutComp() {
    const [content, setContent] = useState("");
    const [rightContent, setRightContent] = useState("");
    useEffect(() => {
        window.scrollTo(0, 0)
        const getContent = async () => {
            await Utils.get_page(2).then((res) => res).then((data) => {
                if (data && typeof data.data.content === "string") {
                    console.log(data.data.content)
                    setContent(DOMPurify.sanitize(data.data.content));
                }
                else
                {
                    setContent("")
                }
                if (data && typeof data.data.right_content === "string") {
                    console.log(data.data.right_content)
                    setRightContent(DOMPurify.sanitize(data.data.right_content));
                }
                else
                {
                    setRightContent("")
                }
                document.title = new DOMParser().parseFromString(data.data.title, "text/html").body.textContent;
            })
        }
        getContent()
    }, []);
        const transform = (node) => {
        if (!node || !node.type) return node; // Prevent null errors

            // Convert <a> tags into <Link> for internal links
            if (node.type === "tag" && node.name === "a") {
                const href = node.attribs?.href || "";

                if (href.startsWith("/")) {
                    return <Link to={href}>{domToReact(node.children)}</Link>; // React Router link
                }
                return (
                    <a href={href} target="_blank" rel="noreferrer">
                        {domToReact(node.children)}
                    </a>
                ); // External link
            }
        return node; // Return unchanged nodes
    };
    return (
             <div style={{marginBottom:'30px'}}>
                 <div className={"flex-child-element:first-child"} align={"left"}
                     style={{float: "right", width: "27%", marginTop: "85px", marginRight: "35px"}}>
                     {parse(rightContent, {replace: transform})}
                 </div>
                 <div className={"flex-child-element"} align={"left"}
                     style={{marginLeft: "50px", marginTop: "10px", marginBottom: "30px", width: "60%"}}>
                     <h2 align={"center"}>IACDC:</h2>
                      <div className={"flex-child-element"} align={"left"}
                 style={{marginLeft: "50px", marginTop: "30px", marginBottom: "30px", width: "60%"}}>
                {parse(content, {replace: transform})}
                </div>
                 </div>

        </div>
    )
}
export default AboutComp
