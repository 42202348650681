import React, {useEffect, useState} from 'react';
import Utils from "../utils/Utils";
import {useNavigate} from "react-router-dom";
import EditModal from "./modals/NewDataset";

const DynamicDropdowns = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleShow = () => setIsModalOpen(true)
    const handleClose = () => setIsModalOpen(false)
    const [currentPath, setCurrentPath] = useState("/carbondata/jeremlib");
    const [selectedPath, setSelectedPath] = useState("");
    const [directories, setDirectories] = useState([]);
    const [symlinkName, setSymlinkName] = useState("");
    const token = localStorage.getItem("token");


  // Function to fetch data based on the current path
  const fetchSubdirectories = async (path) => {
    // Replace with your actual fetch call
      console.log(currentPath);
    const response = await Utils.newDataset(path, token);
        console.log("got it! ", response.data);
        setDirectories(response.data);
       setCurrentPath(path);
      return response.data; // Assume data is an array of subdirectories
  };

  useEffect(() => {
       if (!token) {
            navigate('/')
        }
        else {
           fetchSubdirectories(currentPath)
               
           }
        
  }, [currentPath, navigate, token]);

  const handleDirectoryClick = (path) => {
        setSelectedPath(path);
        fetchSubdirectories(path);
    };

  const create_symlink = async () => {
      if (!selectedPath || !symlinkName) {
            alert("Please select a directory and enter a symlink name.");
            return;
        }
      try {
        const response = await Utils.symlink( {
                target_path: selectedPath,
                link_name: symlinkName,
            }, token)
            setIsModalOpen(true);
            navigate(`/admin_only/page/datasets/${response.data.message}`);
            console.log('your answer: ', response.data.message)
            setSymlinkName(""); // Reset input
            setIsModalOpen(false);
        } catch (error) {
            alert(error.response?.data?.error || "Error creating symlink");
        }
    }
  return (
      <div>
          <h3>Choose the directory you want to add</h3>
          <ul align={"left"} style={{listStyle: "none"}}>
                {directories.map((dir, index) => (
                    <li key={index} onClick={() => handleDirectoryClick(dir.path)} style={{ cursor: "pointer" }}>
                        {dir.name}
                    </li>
                ))}
            </ul>
            <hr />
<h3>Create Symbolic Link</h3>
            <p>Selected Path: {selectedPath || "None"}</p>
            <input
                type="text"
                placeholder="Enter symlink name"
                value={symlinkName}
                onChange={(e) => setSymlinkName(e.target.value)}
            />
            <button className={"custom-button"} onClick={handleShow}>Create Symlink</button>
          <EditModal show={isModalOpen} handleClose={handleClose} handleEdit={create_symlink} />

      </div>
  )
      ;
};

export default DynamicDropdowns;
/* {directories.map((options, index) => (
                  <select style={{marginRight: '5px'}} id={'newDS'} key={index} onChange={(e) => handleChange(e, index)}>
                      <option value="">Select...</option>
                      {options.map((option, i) => (
                          <option key={i} value={option}>
                              {option}
                          </option>
                      ))}
                  </select>
              )
          )}
          <div>

          <div>
              <br/>
              <button className={'button'} onClick={() => {
                  create_symlink(path.join('/'))
              }}>Create Symlink
              </button>*/