import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import NewsTicker from "../services/NewsTicker";
import Utils from "../utils/Utils";
import DOMPurify from "dompurify"; // Import sanitizer
import parse, {domToReact} from "html-react-parser";


function HomePageComp() {
    const [content, setContent] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
        const getContent = async () => {
            await Utils.get_page(1).then((res) => res).then((data) => {
                if (data && typeof data.data.content === "string") {
                    setContent(DOMPurify.sanitize(data.data.content));
                    document.title = new DOMParser().parseFromString(data.data.title, "text/html").body.textContent;
                }
                else
                {
                    setContent("")
                }
            })
        }
        getContent()
    }, [])
    const transform = (node) => {
        if (!node || !node.type) return node; // Prevent null errors

            // Convert <a> tags into <Link> for internal links
            if (node.type === "tag" && node.name === "a") {
                const href = node.attribs?.href || "";

                if (href.startsWith("/")) {
                    return <Link to={href}>{domToReact(node.children)}</Link>; // React Router link
                }
                return (
                    <a href={href} target="_blank" rel="noreferrer">
                        {domToReact(node.children)}
                    </a>
                ); // External link
            }
        return node; // Return unchanged nodes
    };


    return (
        <div id={"homePage"} style={{marginBottom: "25px"}}>
            <div style={{marginTop: "50px"}}>
                    <h6>This research website is under the management of Prof. Pinhas Alpert and Prof. Nili Harnik,
                        for the purpose of atmospheric and environmental data access.</h6>
                <hr style={{marginLeft: "70px", marginRight: "70px"}}/>
                <hr style={{marginLeft: "70px", marginRight: "70px"}}/>
            </div>
            <div className={"flex-child-element:first-child"} align={"left"}
                 style={{float: "right", width: "27%", marginTop: "30px", marginRight: "35px"}}>
                <div>
                    <h3> Datasets for download</h3>
                    <h5>Datasets by name</h5>
                    <Link to={"/data_access"} className={'custom-button'} style={{marginBottom: "10px"}}>Datasets</Link>
                    <br/>
                    <br/>
                    <Link to={"/categories"} className={'custom-button'} style={{marginBottom: "10px"}}>Categories</Link>
                    <br/>
                    <br/>
                </div>
                <div>
                    <NewsTicker/>
                </div>
                <div style={{marginTop: "200px"}}>
                    <a href={"https://english.tau.ac.il/"} target={"_blank"} rel={"noreferrer"}>
                        <img src={"https://english.tau.ac.il/sites/default/files/TAU_Logo_HomePage_Eng.png"}
                             alt={"TAU"}
                             style={{height: "160px", width: "300px"}}/>
                    </a>
                    <br/>
                    <a href={"https://www.gov.il/he/departments/ministry_of_science_and_technology/govil-landing-page"}
                       target={"_blank"} rel={"noreferrer"}>
                        <img src={"https://upload.wikimedia.org/wikipedia/he/6/6f/MIST_logo.jpg"}
                             alt={"Science Ministry"}
                             style={{height: "130px", width: "300px", marginTop: "75px"}}/>
                    </a>
                </div>
            </div>
            <div className={"flex-child-element"} align={"left"}
                 style={{marginLeft: "50px", marginTop: "30px", marginBottom: "30px", width: "60%"}}>
                {parse(content, {replace: transform})}
                </div>
        </div>
    )
}

export default HomePageComp