import {Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";  // Required for accordion behavior
import React, {useEffect, useState} from "react";
import Utils from "../../utils/Utils";
import DOMPurify from "dompurify";
import parse, {domToReact} from "html-react-parser";


function OutIACDC () {
    const [content, setContent] = useState("");
    const [rightContent, setRightContent] = useState("");
    const [accordionItems, setAccordionItems] = useState([]);

    useEffect(() => {
      window.scrollTo(0, 0)
        const getContent = async () => {
            let resp = await Utils.get_page(8)
            setContent(DOMPurify.sanitize(resp.data.content))
            setRightContent(DOMPurify.sanitize(resp.data.right_content))
            setAccordionItems((resp.data.accordion))
            document.title = new DOMParser().parseFromString(resp.data.title, "text/html").body.textContent;
        }
        getContent()
        console.log("set Items" ,accordionItems)
    }, [])
    const transform = (node) => {
        if (!node || typeof node !== "object" || !node.type) return node; // Prevent null errors

            // Convert <a> tags into <Link> for internal links
            if (node.type === "tag" && node.name === "a") {
                const href = node.attribs?.href || "";

                if (href.startsWith("/")) {
                    return <Link to={href}>{domToReact(node.children)}</Link>; // React Router link
                }
                return (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        {domToReact(node.children)}
                    </a>
                ); // External link
            }
        return node; // Return unchanged nodes
    };
    return (
        <div>
            <div align={"left"} style={{marginLeft:"50px", marginTop:"70px", width:"55%", marginBottom:'30px'}}>
                {parse(content, {replace: transform})}
            </div>
            <div className="accordion mt-4" id="dynamicAccordion" style={{marginLeft: "50px", marginTop: "10px", marginBottom: "30px", width: "60%"}}>
                 {accordionItems.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                style={{backgroundColor:"#D3D3D3"}}
                            >
                                {parse(item.header || "", {replace: transform})}
                            </button>
                        </h2>
                        <div
                            id={`collapse${index}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#dynamicAccordion"
                        >
                            <div className="accordion-body" align={"left"}>
                                {parse(item.content || "", {replace: transform})}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default OutIACDC